<template>
  <div class="classroomSearch">
    <!-- TODO -->
   <div style="background: #fff;">
    <div class="row">
      <div class="content-center" style="cursor: pointer">
        <img :src="logoImg"  @click="changeTitle({ key: 'home' })" class="img-Logo" />
        <p @click="govideoClass">视频学堂</p>
      </div>
      <div class="content-right"></div>
    </div>
   </div>
    <!-- TODO 搜索栏 -->
    <div class="classroomSearch_contant">
      <div class="searchPart">
        <el-input
          v-model="input"
          suffix-icon="el-icon-search"
          placeholder="请输入内容"
          @change="getvideoSearch(input)"
        ></el-input>
        <div class="searchPart_list">
          <p class="searchPart_list_title"  v-if="videoSearch.length">
            共为你找到{{ videoSearch ? videoSearch.length : "" }}条搜索结果
          </p>
          <p @click="govideoClass"  style="margin-top:14px;color:rgba(79, 129, 255, 1);margin-left: 30px;cursor: pointer"  v-if="videoSearch.length <= 0 && showNoContent">
            返回视频课堂
          </p>
          <div class="searchPart_listes" v-if="videoSearch.length">
            <div
              class="searchPart_listes_everyone"
              v-for="(item, index) in videoSearch"
              :key="index"
              @click="goclassDetail(item.id, item.lessons_columns[0].id)"
            >
              <p
                class="searchPart_listes_everyone_name"
                v-html="item.title"
                style="cursor: pointer"
              ></p>
              <div class="searchPart_listes_everyone_footer">
                <img :src="item.cover_images"  style="cursor: pointer" alt="" />
                <div class="searchPart_listes_everyone_right">
                  <div v-html="item.describe"></div>
                  <p>
                    分类：{{ item.lessons_columns[item.lessons_columns.length-1].name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no_Content" v-if="videoSearch.length <= 0 && showNoContent">
          <img src="../../assets/smartcat/noContent.png" alt="" />
          <p>暂无结果</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartcatplusIndex",

  data() {
    return {
      logoImg: require("@/assets/smartcat/logo.svg"),
      input: "",
      videoSearch: [],
      showNoContent: false 
    };
  },

  mounted() {},
  created() {
    this.getvideoSearch(this.$route.query.value);
    this.input = this.$route.query.value;
  },
  methods: {
    govideoClass() {
      this.$router.push({
        path: "/videoClass",
      });
    },
    goclassDetail(lessons_id, column_id) {
     this.$router.push({
        path: "/classDetail",
        query: {
          column_id,
          lessons_id,
        },
      });
      // window.open(routeData.href, "_blank");
    },
    changeTitle(item) {
      this.$router.push(item.key);
    },
    // goclassroomSearch() {
    //   this.$request
    //     .videoSearch({ value: this.input ? this.input : "" })
    //     .then((res) => {
    //       if (res.ret) {
    //         this.videoSearch = res.data.data;
    //       }
    //     });
    // },
    getvideoSearch(el) {
      this.$request
        .videoSearch({ type: 2, value: el ? el : "" })
        .then((res) => {
          if (res.ret && res.data.data) {
            this.videoSearch = res.data.data.map((item) => {
              const regex = new RegExp(this.input, "gi");
              const highlightedDescribe = item.describe.replace(
                regex,
                (match) => `<span class="highlight">${match}</span>`
              );
              const highlightedTitle = item.title.replace(
                regex,
                (match) => `<span class="highlight">${match}</span>`
              );

              return {
                ...item,
                describe: highlightedDescribe,
                title: highlightedTitle,
              };
            });
            this.showNoContent = true
          } else {
            this.videoSearch = [];
            this.showNoContent = true
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.classroomSearch {
  min-height: 100vh;
  background: #f8f8f8;
  /deep/.highlight {
    color: rgba(79, 129, 255, 1);
  }
  .row {
    width: 1200px;
    margin: auto;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    line-height: 64px;
  }
  .content-center {
    display: flex;
    align-items: center;
    
    .img-Logo {
      width: 146px;
      height: 40px;
    }
    p {
      width: 80px;
      font-size: 20px;
      font-weight: 700;
      margin: 0;
      color: rgba(0, 0, 0, 1);
      margin-left: 30px;
    }
  }
  .classroomSearch_contant {
    background: #f8f8f8;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 200px;
  }

  .searchPart {
    width: 1200px;
    margin: auto;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    padding: 30px;
  }
  .searchPart_list_title {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: rgba(136, 136, 136, 1);
    margin-top: 44px;
  }
  .searchPart_listes {
    .searchPart_listes_everyone {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(238, 238, 238, 1);

      .searchPart_listes_everyone_name {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        margin: 0;
      }
      .searchPart_listes_everyone_footer {
        margin-top: 18px;
        display: flex;
        img {
          width: 162px;
          height: 90px;
          border-radius: 5.63px;
        }
        .searchPart_listes_everyone_right {
          margin-left: 20px;
          div {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            color: rgba(68, 68, 68, 1);
            margin: 0;
            margin-top: 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break:break-all; 
            
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(136, 136, 136, 1);
            margin-top: 18px;
          }
        }
      }
    }
  }
  .no_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 260px;
      height: 146px;
      margin-top: 40px;
    }
    p {
      margin-top: 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      color: rgba(136, 136, 136, 1);
    }
  }
  /deep/.el-input__inner {
    height: 40px;
    border-radius: 20px !important;
    background: rgba(248, 248, 248, 1);
    border: none;
    padding-left: 40px;
  }
  /deep/.el-input__suffix {
    width: 76px;
    height: 40px;
    right: 1px;
    border-radius: 20px;
    background: rgba(255, 208, 0, 1);
    color: rgba(0, 0, 0, 1);
  }
  /deep/.el-input__icon {
    font-size: 20px;
  }
}
</style>